import React from 'react';
import {useSelector} from "react-redux";
import Menu from "./Components/Menu";
import Header from "./Components/Header";

const Topup = () => {
    const {data, loading} = useSelector(state => state.user)
    return (
        <>
            <div class="matches">
                <Menu/>
                <section className={"ms-5 me-5"} style={{width: "1000px"}}>
                    <Header title="Scout"/>

                    <div className="mb-3 text-white border border-2 border-[#2E323A] bg-[#1E2026] py-3 px-3">
                        <table className={"w-full text-sm text-left rtl:text-right "}>
                            <thead>
                            <tr>
                                <th className="px-6 py-3">Amount</th>
                                <th className="px-6 py-3">Status</th>
                            </tr>
                            </thead>
                            <tbody>

                            {data.topups.length > 0 && data.topups.map((topup) => {
                                return (
                                    <tr>
                                        <td className="px-6 py-4">{topup.amount} $</td>
                                        <td className="px-6 py-4">
                                            <span>
                                              {topup.status == 0
                                                  ? "В ожидании"
                                                  : topup.status == 2
                                                      ? "Подтверждено"
                                                      : "Отменено"}
                                            </span>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Topup;