import React from 'react';
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";

const Menu = () => {
    const nav = useNavigate();
    const location = useLocation();
    const {data, loading} = useSelector(state => state.user)
    return (
        <nav className={"flex flex-col justify-between"}>
            <ul>
                <li className={location.pathname == '/' ? "active" : "notActive"} onClick={() => nav("/")}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4 13.7555V18.0685C4 18.3065 4.224 18.5005 4.5 18.5005H19.5C19.776 18.5005 20 18.3065 20 18.0685V9.47849C20 9.23949 19.776 9.04649 19.5 9.04649H12C11.7 9.04649 11.416 8.91149 11.226 8.67849L8.626 5.50049H4.5C4.224 5.50049 4 5.69349 4 5.93149V13.7555ZM19.5 20.5005H4.5C3.122 20.5005 2 19.4095 2 18.0685V5.93149C2 4.59049 3.122 3.50049 4.5 3.50049H9.101C9.4 3.50049 9.685 3.63449 9.875 3.86749L12.474 7.04649H19.5C20.878 7.04649 22 8.13649 22 9.47849V18.0685C22 19.4095 20.878 20.5005 19.5 20.5005Z"
                            fill="#90AFFF"
                        />
                        <mask
                            id="mask0_9_5276"
                            style={{"mask-type": "luminance"}}
                            maskUnits="userSpaceOnUse"
                            x="2"
                            y="3"
                            width="20"
                            height="18"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4 13.7555V18.0685C4 18.3065 4.224 18.5005 4.5 18.5005H19.5C19.776 18.5005 20 18.3065 20 18.0685V9.47849C20 9.23949 19.776 9.04649 19.5 9.04649H12C11.7 9.04649 11.416 8.91149 11.226 8.67849L8.626 5.50049H4.5C4.224 5.50049 4 5.69349 4 5.93149V13.7555ZM19.5 20.5005H4.5C3.122 20.5005 2 19.4095 2 18.0685V5.93149C2 4.59049 3.122 3.50049 4.5 3.50049H9.101C9.4 3.50049 9.685 3.63449 9.875 3.86749L12.474 7.04649H19.5C20.878 7.04649 22 8.13649 22 9.47849V18.0685C22 19.4095 20.878 20.5005 19.5 20.5005Z"
                                fill="white"
                            />
                        </mask>
                        <g mask="url(#mask0_9_5276)">
                            <rect width="24" height="24"/>
                        </g>
                    </svg>
                    <span>Главная</span>
                </li>

                <li className={location.pathname == '/notify' ? "active" : "notActive"} onClick={() => nav("/notify")}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4 13.7555V18.0685C4 18.3065 4.224 18.5005 4.5 18.5005H19.5C19.776 18.5005 20 18.3065 20 18.0685V9.47849C20 9.23949 19.776 9.04649 19.5 9.04649H12C11.7 9.04649 11.416 8.91149 11.226 8.67849L8.626 5.50049H4.5C4.224 5.50049 4 5.69349 4 5.93149V13.7555ZM19.5 20.5005H4.5C3.122 20.5005 2 19.4095 2 18.0685V5.93149C2 4.59049 3.122 3.50049 4.5 3.50049H9.101C9.4 3.50049 9.685 3.63449 9.875 3.86749L12.474 7.04649H19.5C20.878 7.04649 22 8.13649 22 9.47849V18.0685C22 19.4095 20.878 20.5005 19.5 20.5005Z"
                            fill="#90AFFF"
                        />
                        <mask
                            id="mask0_9_5276"
                            style={{"mask-type": "luminance"}}
                            maskUnits="userSpaceOnUse"
                            x="2"
                            y="3"
                            width="20"
                            height="18"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4 13.7555V18.0685C4 18.3065 4.224 18.5005 4.5 18.5005H19.5C19.776 18.5005 20 18.3065 20 18.0685V9.47849C20 9.23949 19.776 9.04649 19.5 9.04649H12C11.7 9.04649 11.416 8.91149 11.226 8.67849L8.626 5.50049H4.5C4.224 5.50049 4 5.69349 4 5.93149V13.7555ZM19.5 20.5005H4.5C3.122 20.5005 2 19.4095 2 18.0685V5.93149C2 4.59049 3.122 3.50049 4.5 3.50049H9.101C9.4 3.50049 9.685 3.63449 9.875 3.86749L12.474 7.04649H19.5C20.878 7.04649 22 8.13649 22 9.47849V18.0685C22 19.4095 20.878 20.5005 19.5 20.5005Z"
                                fill="white"
                            />
                        </mask>
                        <g mask="url(#mask0_9_5276)">
                            <rect width="24" height="24"/>
                        </g>
                    </svg>
                    <span>Уведомления
                        {data.notifications ? (
                            <span className={"notif"}>NEW</span>
                        ) : (<></>)}
                    </span>
                </li>


                <li className={location.pathname == '/scout' ? "active" : "notActive"} onClick={() => nav("/scout")}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4 13.7555V18.0685C4 18.3065 4.224 18.5005 4.5 18.5005H19.5C19.776 18.5005 20 18.3065 20 18.0685V9.47849C20 9.23949 19.776 9.04649 19.5 9.04649H12C11.7 9.04649 11.416 8.91149 11.226 8.67849L8.626 5.50049H4.5C4.224 5.50049 4 5.69349 4 5.93149V13.7555ZM19.5 20.5005H4.5C3.122 20.5005 2 19.4095 2 18.0685V5.93149C2 4.59049 3.122 3.50049 4.5 3.50049H9.101C9.4 3.50049 9.685 3.63449 9.875 3.86749L12.474 7.04649H19.5C20.878 7.04649 22 8.13649 22 9.47849V18.0685C22 19.4095 20.878 20.5005 19.5 20.5005Z"
                            fill="#90AFFF"
                        />
                        <mask
                            id="mask0_9_5276"
                            style={{"mask-type": "luminance"}}
                            maskUnits="userSpaceOnUse"
                            x="2"
                            y="3"
                            width="20"
                            height="18"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4 13.7555V18.0685C4 18.3065 4.224 18.5005 4.5 18.5005H19.5C19.776 18.5005 20 18.3065 20 18.0685V9.47849C20 9.23949 19.776 9.04649 19.5 9.04649H12C11.7 9.04649 11.416 8.91149 11.226 8.67849L8.626 5.50049H4.5C4.224 5.50049 4 5.69349 4 5.93149V13.7555ZM19.5 20.5005H4.5C3.122 20.5005 2 19.4095 2 18.0685V5.93149C2 4.59049 3.122 3.50049 4.5 3.50049H9.101C9.4 3.50049 9.685 3.63449 9.875 3.86749L12.474 7.04649H19.5C20.878 7.04649 22 8.13649 22 9.47849V18.0685C22 19.4095 20.878 20.5005 19.5 20.5005Z"
                                fill="white"
                            />
                        </mask>
                        <g mask="url(#mask0_9_5276)">
                            <rect width="24" height="24"/>
                        </g>
                    </svg>
                    <span>Scout</span>
                </li>
                <li className={location.pathname == '/text' ? "active" : "notActive"} onClick={() => nav("/text")}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4 13.7555V18.0685C4 18.3065 4.224 18.5005 4.5 18.5005H19.5C19.776 18.5005 20 18.3065 20 18.0685V9.47849C20 9.23949 19.776 9.04649 19.5 9.04649H12C11.7 9.04649 11.416 8.91149 11.226 8.67849L8.626 5.50049H4.5C4.224 5.50049 4 5.69349 4 5.93149V13.7555ZM19.5 20.5005H4.5C3.122 20.5005 2 19.4095 2 18.0685V5.93149C2 4.59049 3.122 3.50049 4.5 3.50049H9.101C9.4 3.50049 9.685 3.63449 9.875 3.86749L12.474 7.04649H19.5C20.878 7.04649 22 8.13649 22 9.47849V18.0685C22 19.4095 20.878 20.5005 19.5 20.5005Z"
                            fill="#90AFFF"
                        />
                        <mask
                            id="mask0_9_5276"
                            style={{"mask-type": "luminance"}}
                            maskUnits="userSpaceOnUse"
                            x="2"
                            y="3"
                            width="20"
                            height="18"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4 13.7555V18.0685C4 18.3065 4.224 18.5005 4.5 18.5005H19.5C19.776 18.5005 20 18.3065 20 18.0685V9.47849C20 9.23949 19.776 9.04649 19.5 9.04649H12C11.7 9.04649 11.416 8.91149 11.226 8.67849L8.626 5.50049H4.5C4.224 5.50049 4 5.69349 4 5.93149V13.7555ZM19.5 20.5005H4.5C3.122 20.5005 2 19.4095 2 18.0685V5.93149C2 4.59049 3.122 3.50049 4.5 3.50049H9.101C9.4 3.50049 9.685 3.63449 9.875 3.86749L12.474 7.04649H19.5C20.878 7.04649 22 8.13649 22 9.47849V18.0685C22 19.4095 20.878 20.5005 19.5 20.5005Z"
                                fill="white"
                            />
                        </mask>
                        <g mask="url(#mask0_9_5276)">
                            <rect width="24" height="24"/>
                        </g>
                    </svg>
                    <span>Bookmakers Market</span>
                </li>

                <li className={location.pathname == '/stream' ? "active" : "notActive"} onClick={() => nav("/stream")}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4 13.7555V18.0685C4 18.3065 4.224 18.5005 4.5 18.5005H19.5C19.776 18.5005 20 18.3065 20 18.0685V9.47849C20 9.23949 19.776 9.04649 19.5 9.04649H12C11.7 9.04649 11.416 8.91149 11.226 8.67849L8.626 5.50049H4.5C4.224 5.50049 4 5.69349 4 5.93149V13.7555ZM19.5 20.5005H4.5C3.122 20.5005 2 19.4095 2 18.0685V5.93149C2 4.59049 3.122 3.50049 4.5 3.50049H9.101C9.4 3.50049 9.685 3.63449 9.875 3.86749L12.474 7.04649H19.5C20.878 7.04649 22 8.13649 22 9.47849V18.0685C22 19.4095 20.878 20.5005 19.5 20.5005Z"
                            fill="#90AFFF"
                        />
                        <mask
                            id="mask0_9_5276"
                            style={{"mask-type": "luminance"}}
                            maskUnits="userSpaceOnUse"
                            x="2"
                            y="3"
                            width="20"
                            height="18"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4 13.7555V18.0685C4 18.3065 4.224 18.5005 4.5 18.5005H19.5C19.776 18.5005 20 18.3065 20 18.0685V9.47849C20 9.23949 19.776 9.04649 19.5 9.04649H12C11.7 9.04649 11.416 8.91149 11.226 8.67849L8.626 5.50049H4.5C4.224 5.50049 4 5.69349 4 5.93149V13.7555ZM19.5 20.5005H4.5C3.122 20.5005 2 19.4095 2 18.0685V5.93149C2 4.59049 3.122 3.50049 4.5 3.50049H9.101C9.4 3.50049 9.685 3.63449 9.875 3.86749L12.474 7.04649H19.5C20.878 7.04649 22 8.13649 22 9.47849V18.0685C22 19.4095 20.878 20.5005 19.5 20.5005Z"
                                fill="white"
                            />
                        </mask>
                        <g mask="url(#mask0_9_5276)">
                            <rect width="24" height="24"/>
                        </g>
                    </svg>
                    <span>Stream</span>
                </li>
                
            </ul>
            <ul>
                <li className={"notActive cursor-pointer mt-5"} onClick={() => {
                    localStorage.removeItem("client-token")
                    nav("/auth");
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"/>
                    </svg>
                    <span>Logout</span>
                </li>
            </ul>
        </nav>
    );
};

export default Menu;