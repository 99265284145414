import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";

import Matches from "./views/Matches";
import Auth from "./views/Auth";
import Notify from "./views/Notify";
import Texts from "./views/Texts";
import Scout from "./views/Scout";
import {useDispatch, useSelector} from "react-redux";
import {getData, updateData, readNotif} from "./store/userSlice";
import Topup from "./views/Topup";
import Events from "./views/Events";
import Stream from "./views/Stream";

const App = () => {
    const dispatch = useDispatch()
    const token = localStorage.getItem("client-token")
    const {data, loading, error} = useSelector(state => state.user)
    const nav = useNavigate();


    useEffect(()=>{
        if(error == "error"){
            localStorage.removeItem("client-token")
            nav("/auth");
        }
    }, [error])

    useEffect(()=>{
        async function check(token){
            let res = await dispatch(getData(token))
            if(res.payload && res.payload.status == "error"){
                localStorage.removeItem("client-token")
                nav("/auth");
            }
        }
        if(token){
            check(token)
        }
    }, [dispatch, token])

    useEffect(()=>{
        if(token){
            const interv = setInterval(() => {
                dispatch(updateData(token))
            }, 5000);
            return () => clearInterval(interv);
        }
    }, [dispatch, token])

    useEffect(()=>{
        if(error){
            localStorage.removeItem("client-token")
            nav("/auth");
        }
    }, [dispatch, data, error])

    return (
        <>
        <Routes>
            <Route path="/" element={<Matches/>}/>
            <Route path="/auth" element={<Auth/>}/>
            <Route path="/notify" element={<Notify/>}/>
            <Route path="/text" element={<Texts/>}/>
            <Route path="/scout" element={<Scout/>}/>
            <Route path="/topup" element={<Topup/>}/>
            <Route path="/stream" element={<Stream/>}/>
        </Routes>
        </>
    );
};

export default App;
