import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import Menu from "./Components/Menu";
import Header from "./Components/Header";
import PrimaryButton from './Components/PrimaryButton';
import {getDataEvents} from "../store/EventSlice";

const Stream = () => {
    const token = localStorage.getItem("client-token")
    const dispatch = useDispatch()

    return (
        <>

            <div className="matches">
                <Menu/>
                <section className={"ms-5 me-5"} style={{width: "1000px"}}>
                    <Header title="Stream"/>

                    <div className="mb-3 text-white  text-center items center py-3 px-3">
                        
                    <img src="/pngwing.com.png" className="mx-auto" width="500px" alt="" />
                        
                    </div>
            </section>
        </div>
    </>
    );
};

export default Stream;