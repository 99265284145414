import { configureStore } from '@reduxjs/toolkit';

import userSlice from "./userSlice";
import EventSlice from "./EventSlice";

export default configureStore({
    reducer: {
        user: userSlice,
        event: EventSlice,
    },
});