import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {getEvents} from "../api";

export const getDataEvents = createAsyncThunk('event/getData', async () => {
    return await getEvents()
})


const EventSlice = createSlice({
    name: 'event',
    initialState: {
        data: {},
        loading: false,
        error: null
    },
    extraReducers: {
        [getDataEvents.pending]: (state) => {
            state.loading = true;
        },
        [getDataEvents.fulfilled]: (state, action) => {
            if(action.payload.erro !== true){
                state.data = action.payload.itens;
            }else{
                state.error = action.payload.status;
            }
            state.loading = false;
        },
        [getDataEvents.rejected]: (state, action) => {
            state.loading = false;
        },
    }
});

export default EventSlice.reducer;