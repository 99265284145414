import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {io} from "socket.io-client";
import closeIcon from "../svg/close.svg";

import {getData} from "../api";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import Menu from "./Components/Menu";
import Header from "./Components/Header";

const Matches = () => {
    const nav = useNavigate();
    const {data, loading} = useSelector(state => state.user)
/*    const [data, setData] = useState({
        matches: [],
        mail: [],
        balance: 0,
    });*/
    const [sounds, setSounds] = useState({
        goal1: new Audio(require("../sounds/goal1.mp3")),
        goal2: new Audio(require("../sounds/goal2.mp3")),
        ball1: new Audio(require("../sounds/ball1.mp3")),
        ball2: new Audio(require("../sounds/ball2.mp3")),
        corner1: new Audio(require("../sounds/corner1.mp3")),
        corner2: new Audio(require("../sounds/corner2.mp3")),
        danger1: new Audio(require("../sounds/danger1.mp3")),
        danger2: new Audio(require("../sounds/danger2.mp3")),
        freeKick1: new Audio(require("../sounds/freeKick1.mp3")),
        freeKick2: new Audio(require("../sounds/freeKick2.mp3")),
        redCard1: new Audio(require("../sounds/redCard1.mp3")),
        redCard2: new Audio(require("../sounds/redCard2.mp3")),
        yellowCard1: new Audio(require("../sounds/yellowCard1.mp3")),
        yellowCard2: new Audio(require("../sounds/yellowCard2.mp3")),
        penalty: new Audio(require("../sounds/penalty.mp3")),
        plusOne1: new Audio(require("../sounds/plusOne1.mp3")),
        plusOne2: new Audio(require("../sounds/plusOne2.mp3")),
        plusTwo1: new Audio(require("../sounds/plusTwo1.mp3")),
        plusTwo2: new Audio(require("../sounds/plusTwo2.mp3")),
        plusThree1: new Audio(require("../sounds/plusThree1.mp3")),
        plusThree2: new Audio(require("../sounds/plusThree2.mp3")),
        "1ball": new Audio(require("../sounds/1ball.mp3")),
        "2ball": new Audio(require("../sounds/2ball.mp3")),
        fall: new Audio(require("../sounds/fall.mp3")),
        stop: new Audio(require("../sounds/stop.mp3")),
    });
    const [connected, setConnected] = useState({});
    const [connectedSocket, setConnectedSocket] = useState();

/*    async function fetchData() {
        const _data = await getData(localStorage.getItem("client-token"));
        if (_data.status !== "success") {
            localStorage.removeItem("client-token");
            nav("/auth");
        }
        if (connected.name) {
            setData({
                ..._data.data,
                balance: data.balance,
            });
        } else {
            setData(_data.data);
        }
    }*/

    useEffect(() => {
        if (!localStorage.getItem("client-token")) {
            nav("/auth");
        }
/*
        const interv = setInterval(() => {
            fetchData();
        }, 5000);

        fetchData();

        return () => clearInterval(interv);*/
    }, []);

    const handleMatchClick = (match) => {
        if(!data.matches.length){
            return
        }
        if (match.status == 0) {
            return toast.error("Матч еще не начался!");
        }

        if (data.balance < match.price) {
            return toast.error("Недостаточно средств");
        }

        const socket = io("http://localhost:8000", {
            query: {
                token: localStorage.getItem("client-token"),
                match: match.id,
            },
        });

        socket.on("disconnect", () => {
            toast.error("Отключен");
            setConnectedSocket(null);
            setConnected({});
        });

        socket.on("connect", () => {
            toast.success("Подключен");
            setConnected(match);
            setConnectedSocket(socket);
        });

        // socket.on("newBalance", (payload) => {
        //   setData({
        //     ...data,
        //     balance: Number(payload),
        //   });
        // });

        socket.on("matchData", (payload) => {
            setConnected(payload);
        });

        socket.on("goal-1", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.goal1.play();
        });
        socket.on("goal-2", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.goal2.play();
        });
        socket.on("ball-1", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.ball1.play();
        });
        socket.on("ball-2", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.ball2.play();
        });
        socket.on("corner-1", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.corner1.play();
        });
        socket.on("corner-2", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.corner2.play();
        });
        socket.on("danger-1", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.danger1.play();
        });
        socket.on("danger-2", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.danger2.play();
        });
        socket.on("freeKick-1", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.freeKick1.play();
        });
        socket.on("freeKick-2", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.freeKick2.play();
        });
        socket.on("yellowCard-1", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.yellowCard1.play();
        });
        socket.on("yellowCard-2", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.yellowCard2.play();
        });
        socket.on("redCard-1", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.redCard1.play();
        });
        socket.on("redCard-2", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.redCard2.play();
        });
        socket.on("penalty-2", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.penalty.play();
        });
        socket.on("penalty-1", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.penalty.play();
        });


        socket.on("plusOne-1", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.plusOne1.play();
        });

        socket.on("plusOne-2", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.plusOne2.play();
        });

        socket.on("plusTwo-1", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.plusTwo1.play();
        });

        socket.on("plusTwo-2", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.plusTwo2.play();
        });

        socket.on("plusThree-1", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.plusThree1.play();
        });

        socket.on("plusThree-2", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.plusThree2.play();
        });

        socket.on("2ball-1", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds["1ball"].play();
        });

        socket.on("2ball-2", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds["2ball"].play();
        });

        socket.on("fall-1", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.fall.play();
        });

        socket.on("fall-2", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
                sounds[soundKey].currentTime = 0; // Сбрасываем позицию воспроизведения в начало
            }
            sounds.fall.play();
        });

        socket.on("stop-1", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
            }
            sounds.stop.play();
        });

        socket.on("stop-2", (payload) => {
            for (let soundKey of Object.keys(sounds)) {
                sounds[soundKey].pause();
            }
            sounds.stop.play();
        });
    };

    return (
        <>
            <div class="matches">
                <Menu />
                <section className={"ms-5 me-5"} style={{width: "1000px"}}>
                    <Header title="Главная"/>
                    <div className="cards">
                        {!connectedSocket && (
                            <div className="item">
                                <div className="title">Баланс</div>
                                {loading ? (
                                    <FontAwesomeIcon icon={faSpinner} spinPulse className={"value"}/>
                                ) : (
                                    <div className="value">{data && data.balance ? data.balance.toFixed(2) : 0}</div>
                                )
                                }
                            </div>
                        )}
                        <div className="item">
                            <div className="title">Доступ до</div>
                            <div className="value">
                                {loading ? (
                                    <FontAwesomeIcon icon={faSpinner} spinPulse/>
                                ) : (
                                    <div>{data ? new Date(data.activeUntil).toLocaleDateString() : "-"}</div>
                                )
                                }
                            </div>
                        </div>
                    </div>

                    <div className={"list-matches"}>
                        <h3>Футбол</h3>
                        {data.matches.length > 0 && data.matches
                            .filter((item) => item.type == "football")
                            .map((item) => {
                                return (
                                    <div className={"item flex justify-between"} onClick={() => handleMatchClick(item)}>
                                        <div className="">
                                            {item.name}{" "}
                                            <span>
                      {item.status == 0
                          ? "Запланировано"
                          : item.status == 1
                              ? "В эфире"
                              : "В эфире"}
                    </span>
                                        </div>
                                        <span className={"ms-5"}>Цена: {item.price} за минуту</span>
                                    </div>
                                );
                            })}
                    </div>
                    <br/>
                    <div className={"list-matches"}>
                        <h3>Хоккей</h3>

                    </div>
                    <br/>
                    <div className={"list-matches"}>
                        <h3>Баскетбол</h3>
                        {data.matches.length > 0 && data.matches
                            .filter((item) => item.type == "basketball")
                            .map((item) => {
                                return (
                                    <div className={"item flex justify-between"} onClick={() => handleMatchClick(item)}>
                                        <div className="">
                                            {item.name}{" "}
                                            <span>
                      {item.status == 0
                          ? "Запланировано"
                          : item.status == 1
                              ? "В эфире"
                              : "В эфире"}
                    </span>
                                        </div>
                                        <span className={"ms-5"}>Цена: {item.price} за минуту</span>
                                    </div>
                                );
                            })}
                    </div>
                    <br/>
                    <div className={"list-matches flex justify-between"}>
                        <h3>CYBER</h3>
                            <div className="text-yellow-500 font-bold">COMING SOON</div>
                    </div>
                    <br/>
                            </section>
                            </div>

                                {
                                    connected.name && (
                                        <div class="nameofmatch2">
                                            <div>
                                                <h1>{connected.name}</h1>
                                                <img
                                                    src={closeIcon}
                                                    onClick={() => {
                                                        connectedSocket.disconnect();
                                                        setConnectedSocket(null);
                                                        setConnected({});
                                                    }}
                                                    alt="close"
                                                />
                                                <div className="score">
                                                    <div className="item">{connected.score.team1}</div>
                                                    <div className="item separator">|</div>
                                                    <div className="item">{connected.score.team2}</div>
                                                </div>

                                                <div className="comment">
                                                    {connected.comment ? (
                                                        <>
                                                            <span>Комментарий:</span> {connected.comment}
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                            )
                                ;
                            };

                            export default Matches;
